import { DisplayCase } from './display-case.component';
import { connect } from 'react-redux';
import { fetchCasesById } from '../../redux/case/case.epics';

const mapStateToProps = (state) => {
  return {
    error: state.case.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubmit: (data) => {
      dispatch(fetchCasesById(data));
    },
  };
};

export const DisplayCaseContainer = connect(mapStateToProps, mapDispatchToProps)(DisplayCase);
