import React from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import { redirectToLogin } from '../../shared/authService';

function Error()  {
    return (
        <section className="error px-3 px-sm-5 py-5">
            <Container>
                <Row>
                    <Col xs="12" md={{ size: 6, offset: 3 }}  className="text-center">
                    <img top src="assets/images/404.svg" alt="404 Error" className="w-100"  />
                        <h1 className="mt-5">Oops! Page Not Found</h1>
                        <Button onClick={() => {
                            redirectToLogin();
                        }} className="btn btn-takamol text-white btn-lg mt-3 mb-5">Go To Home Page</Button>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default (Error);
