import React, { useEffect } from 'react';
import { caseStatus, surveyRating } from '../../shared/constants';
import { Container, Row, Col, Card, CardBody, Label, Button, Input, FormFeedback } from 'reactstrap';
import { Form, Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

export function Case(props) {
   const { 
      fetchCasesById,
      singleCase,
      handleSubmit,
      loading,
      message
   } = props;
   
   const history = useHistory();

   const caseId = props.computedMatch.params.caseId;

   useEffect(() => {
      // if (!singleCase){
         fetchCasesById(caseId)
      // }
   }, []);

   return (
      <section className="view-case px-3 px-sm-5 py-5">
         { !!singleCase && singleCase[0] && <Container>
            <Row>
               <Col className="text-center mb-5">
                  <Label>Ticket number</Label>
                  <h1>{singleCase[0].ticketnumber}</h1>
               </Col>
            </Row>
            <Row>
               <Col xs="12" md={{ size: 8, offset: 2 }} xl={{ size: 6, offset: 3 }}>
                  <Card>
                     <CardBody>
                        <Row>
                           <Col xs="12" sm="6">
                              <Label>Title</Label>
                              <p>{singleCase[0].title}</p>
                           </Col>
                           <Col xs="12" sm="6">
                              <Label>Priority Code</Label>
                              <p>{singleCase[0].prioritycode}</p>
                           </Col>
                           <Col xs="12" sm="6">
                              <Label>Status</Label>
                              <p>{caseStatus[singleCase[0].statuscode]}</p>
                           </Col>
                           <Col xs="12" sm="6">
                              <Label>Category</Label>
                              <p>{singleCase[0].category && singleCase[0].category.name}</p>
                           </Col>
                           <Col xs="12" sm="6">
                              <Label>Sub-category</Label>
                              <p>{singleCase[0].subCategory && singleCase[0].subCategory.name}</p>
                           </Col>
                           {singleCase[0].project &&
                           <Col xs="12" sm="6">
                              <Label>Project</Label>
                              <p>{singleCase[0].project && singleCase[0].project.name}</p> 
                           </Col>
                            }
                        </Row>
                        {singleCase[0].statuscode === 5 &&
                        <>
                        
                        <Row>
                           <Col>
                              <h2 className="mt-5 mb-3">Submit survey</h2>
                              <p className="mb-5">Please submit the survey</p>
                              { message? message :
                              <Form 
                              onSubmit={(values) => handleSubmit(values)}
                              mutators={{
                                 ...arrayMutators
                              }}
                              initialValues={{
                                 survey: singleCase[0].survey
                              }}>
                              {({ handleSubmit, valid }) => (
                              <form onSubmit={handleSubmit}>
                                 <>
                                 <FieldArray 
                                    name="survey" 
                                    validate={(values) => {
                                       const errorArray = [];

                                       values.forEach(value => {
                                          const errors = {};
                                      
                                          if (!value.surveyRating) errors.surveyRating = 'Required';
                                      
                                          errorArray.push(errors);
                                        });
                                       
                                       if (errorArray.some(object => !isEmpty(object))) {
                                          return errorArray;
                                       } else {
                                          return undefined;
                                       }
                                    }}
                                 >
                                 {({ fields }) =>
                                       singleCase[0].survey && singleCase[0].survey.map((survey, index) => (
                                          <Row className="mb-5" key={index}>
                                             <Col xs="12">
                                                <Label>{survey.name}</Label>
                                                <Field name={`survey[${index}].surveyId`} component="input" type="hidden"/>
                                                <Field name={`survey[${index}].surveyRating`}>
                                                   {({ input, meta }) => (
                                                      <div>
                                                         <Input
                                                         {...input}
                                                         type="select"
                                                         className="form-control"
                                                         invalid={meta.error && meta.touched}
                                                         >
                                                         <option />
                                                         {surveyRating && surveyRating.map((option, index) => (
                                                            <option key={index} value={option.value}>
                                                               {option.value}
                                                            </option>
                                                         ))}
                                                         </Input>
                                                         {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                                                      </div>
                                                   )}
                                                </Field>
                                             </Col>
                                          </Row>
                                       ))
                                    }
                                 </FieldArray>
                                 <Row className="mt-3">
                                    <Col className="d-flex justify-content-between w-100 align-items-center">
                                       <Button className="btn btn-takamol btn-lg" block disabled={loading || !valid} type="submit">
                                          Submit
                                       </Button>
                                    </Col>
                                 </Row>
                                 </>
                              </form>
                              )}
                              </Form>
                              }
                              
                           </Col>
                        </Row>
                        </>
                        }
                     </CardBody>
                  </Card>
               </Col>
            </Row>
            <Row>
               <Col className="text-center my-5">
                  <Button onClick={() => {
                  history.goBack();
                }} className="btn btn-takamol text-white btn-lg">Back</Button>
               </Col>
            </Row>
         </Container>
}
      </section>
    );
  
}