export const paths = {
    home: '/',
    login: '/login',
    guest: '/guest',
    addCase: '/add-case',
    addCaseSuccess: '/add-case/success',
    displayCase: '/display-case',
    listCases: '/cases',
    user: '/user',
    viewCase: '/case/:caseId',
    error: '/error'
};
  