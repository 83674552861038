import React from "react";
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { logout } from '../redux/auth/auth.epics';
import { getCustomerId } from '../shared/authService';


function Header(props) {
    const { logout, loggedin } = props;
    const customerId = getCustomerId();

    return (
        <nav className="navbar navbar-expand-lg navbar-light shadow">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center w-100">
                        <a className="navbar-brand" href="/">
                            <img src="assets/images/takamol-logo.svg" className="header__logo" alt="takamol logo" />
                        </a>
                        <button className="navbar-toggler d-none" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>

                    {(customerId || loggedin) && 
                        <Button color="link" onClick={() => logout()}>
                            <span>Logout</span>
                        </Button>
                    }
                    </div>
                </div>
        </nav>
    );
}

const mapStateToProps = (state) => {
    return {
        loggedin: state.auth.loggedin
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
      logout: () => dispatch(logout()),
    };
  };
  
export default connect(mapStateToProps, mapDispatchToProps)(Header);
