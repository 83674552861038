// 1.1.6	GET ALL CASES BY CUSTOMER ID
// 1.1.7	GET ALL CASES BY ID
// 1.1.8	CREATE CASE FOR LOGGED IN USER
// 1.1.9	CREATE CASE FOR ANONYMOUS USER
// 1.1.10	UPDATE CASE SURVEY

export const GET_CASES_BY_CUSTOMER_ID = 'GET_CASES_BY_CUSTOMER_ID';
export const GET_CASES_BY_CUSTOMER_ID_SUCCESS = 'GET_CASES_BY_CUSTOMER_ID_SUCCESS';
export const GET_CASES_BY_CUSTOMER_ID_FAILED = 'GET_CASES_BY_CUSTOMER_ID_FAILED';

export const GET_CASES_BY_ID = 'GET_CASES_BY_ID';
export const GET_CASES_BY_ID_SUCCESS = 'GET_CASES_BY_ID_SUCCESS';
export const GET_CASES_BY_ID_FAILED = 'GET_CASES_BY_ID_FAILED';

export const ADD_CASE_LOGGEDIN = 'ADD_CASE_LOGGEDIN';
export const ADD_CASE_LOGGEDIN_SUCCESS = 'ADD_CASE_LOGGEDIN_SUCCESS';
export const ADD_CASE_LOGGEDIN_FAILED = 'ADD_CASE_LOGGEDIN_FAILED';

export const ADD_CASE_GUEST = 'ADD_CASE_GUEST';
export const ADD_CASE_GUEST_SUCCESS = 'ADD_CASE_GUEST_SUCCESS';
export const ADD_CASE_GUEST_FAILED = 'ADD_CASE_GUEST_FAILED';

export const UPDATE_CASE_SURVEY = 'UPDATE_CASE_SURVEY';
export const UPDATE_CASE_SURVEY_SUCCESS = 'UPDATE_CASE_SURVEY_SUCCESS';
export const UPDATE_CASE_SURVEY_FAILED = 'UPDATE_CASE_SURVEY_FAILED';