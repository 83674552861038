import React, { useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { Container, Row, Col, Button, Label, Input, FormFeedback } from 'reactstrap';
import { Error } from '../../components/error.component';
import { Link } from 'react-router-dom';
import { paths } from '../../shared/paths'
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { setCaseOrigin, getCaseOrigin } from '../../shared/caseOriginService';
import { getCustomerId, redirectToListCases } from '../../shared/authService';
import { caseOriginArray } from '../../shared/constants';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function Login(props) {
  const { handleSubmit, loading, error } = props;
  const history = useHistory();
  let query = useQuery();
  var customerId = getCustomerId();
  
  var caseOrigin = query.get("origin") || getCaseOrigin();

  function isCaseOriginExist (caseOrigin) {
    let obj = caseOriginArray.find(o => o.value == caseOrigin);
    return obj
  }

  useEffect(() => {
    if (customerId) { redirectToListCases(); }
    if (!caseOrigin || !isCaseOriginExist(caseOrigin)) {
      history.replace(`/guest`);
    } else {
      setCaseOrigin(caseOrigin);
    }
  });


  return (
        <section className="login">
        <Container fluid={true}>

        <Row>
        <Col xs="12" md="8" className="p-5 home-hero">
          <h1>Welcome to Takamol CRM</h1>
          <p>Login with your credentials or continue as a guest.</p>
        </Col>

        <Col xs="12" md="4" className="p-5">
        <Row>
            <Col className="text-center mb-5">
              <h2>Login</h2>
            </Col>
          </Row>
          { error && (
          <Row>
            <Col>
              <Error className="mb-5" error={error} />
            </Col>
          </Row>
          )
          }

          <Row>
            <Col xs="12">
                <Form 
                  onSubmit={handleSubmit}
                  validate={(values) => {
                    const errors = {};
                    if (!values.userName) {
                      errors.userName = "Required";
                    }
                    if (!values.password) {
                      errors.password = "Required";
                    }
                    return errors;
                  }}>
                {({ handleSubmit, valid }) => (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <Col className="d-flex flex-column mb-3">
                        <Label>Username</Label>
                        <Field name="userName">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="text"
                                className="form-control"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                            </div>
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex flex-column mb-3">
                        <Label>Password</Label>
                        <Field name="password">
                          {({ input, meta }) => (
                            <div>
                              <Input
                                {...input}
                                type="password"
                                className="form-control"
                                invalid={meta.error && meta.touched}
                              />
                              {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                            </div>
                          )}
                        </Field>
                      </Col>
                    </Row>

                    <Button className="btn btn-takamol btn-lg w-100" block type="submit" disabled={loading || !valid}>
                      Login
                    </Button>
                  </form>
                )}
              </Form>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="text-center my-3">
              <p>OR</p>
              <Link to={paths.guest} className="btn btn-outline-takamol w-100 text-white btn-lg">Continue as Guest</Link>
            </Col>
          </Row>
        </Col>

        </Row>
          
        </Container>
      </section>
      );
}
