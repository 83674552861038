import history from '../history';
import { paths } from '../shared/paths';

// Token
export function setTokens(token) {
    localStorage.setItem('AUTH_KEY', token);
}

export function getToken() {
    return localStorage.getItem('AUTH_KEY');
}

export function clearTokens() {
    localStorage.removeItem('AUTH_KEY');
    window.location.href = paths.home;
}

export function handleUnauthorized() {
    clearTokens();
    removeCustomerId();
}

export function isAuthenticated() {
    var token = getToken()
    return token ? true : false
}

// Customer id
// Customer id used in most of the APIs. Also, in some pages used to check if user is logged in or not
export function setCustomerId(customerId) {
    localStorage.setItem('CUSTOMER_ID', customerId);
}

export function getCustomerId() {
    return localStorage.getItem('CUSTOMER_ID');
}

export function removeCustomerId() {
    localStorage.removeItem('CUSTOMER_ID');
}

export function redirectToLogin(caseOrigin) {
    history.replace(`${paths.login}?origin=${caseOrigin}`)
}

export function redirectToListCases() {
    history.replace(paths.listCases)
}

export function formBody(params) {
    var formBody = [];
    for (var property in params) {
    var encodedKey = encodeURIComponent(property);
    var encodedValue = encodeURIComponent(params[property]);
    formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");

    return formBody
}
