import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { Container, Row, Col, Button, Card, CardBody, Label, FormFeedback, Input} from 'reactstrap';
import { Error } from '../../components/error.component';
import { getCaseOrigin } from '../../shared/caseOriginService';
import { useHistory } from 'react-router-dom';
import { getCustomerId } from '../../shared/authService';

export function AddCase(props) {
        const { 
          handleSubmit, 
          error,
          loading,
          categories,
          subCategories,
          projects,
          fetchCategories, 
          fetchSubcategories,
          fetchProjectsbyCustomerId,
        } = props;

        const customerId = getCustomerId();

        const history = useHistory();
        const [projectCheck, setprojectCheck] = useState(false);
        
        const handleCategoryChange = (selectedCategory, change) => {
          if (selectedCategory) {
            fetchSubcategories(selectedCategory)
          }
          change('subCategoryId', undefined);
          change('categoryId', selectedCategory);
        };

        useEffect(() => {
          const caseOrigin = getCaseOrigin()
          
          fetchCategories();

          if (customerId && caseOrigin) {
            fetchProjectsbyCustomerId(customerId, caseOrigin);
          }

        }, [fetchCategories, fetchProjectsbyCustomerId, customerId]);
        
        return (
            <section className="add-case px-3 px-sm-5 py-5">
            <Container>
              <Row>
                <Col className="text-center mb-5">
                  <h1>Add Case</h1>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 3 }}>
                  { error && (
                    <Row>
                      <Col>
                        <Error error={error} />
                      </Col>
                    </Row>
                  )
                  }
                  <Card className="form-card">
                    <CardBody>
                    <Form
                      onSubmit={(values) => handleSubmit(values, customerId)}
                      validate={(values) => {
                        const errors = {};
                        if (!values.name?.trim() && !customerId) {
                          errors.name = "Required";
                        }
                        if (!values.email?.trim() && !customerId) {
                          errors.email = "Required";
                        }
                        if (!values.title?.trim()) {
                          errors.title = "Required";
                        }
                        if (!values.categoryId) {
                          errors.categoryId = "Required";
                        }
                        if (!values.subCategoryId) {
                          errors.subCategoryId = "Required";
                        }
                        return errors;
                      }}
                    >
                    {({ handleSubmit, form, valid }) => (
                      <form onSubmit={handleSubmit}>
                            {!customerId &&
                            (<>
                              <h2 className="mb-3">General</h2>
                              <Row className="mb-5">
                                <Col xs="12">
                                  <Label>Name</Label>
                                  <Field name="name">
                                    {({ input, meta }) => (
                                      <div>
                                        <Input
                                          {...input}
                                          type="text"
                                          placeholder="Full Name"
                                          className="form-control"
                                          invalid={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                                      </div>
                                    )}
                                  </Field>
                                </Col>
                                <Col>
                                  <Label>Email</Label>
                                  <Field name="email">
                                    {({ input, meta }) => (
                                      <div>
                                        <Input
                                          {...input}
                                          type="email"
                                          placeholder="name@example.com"
                                          className="form-control"
                                          invalid={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                                      </div>
                                    )}
                                  </Field>
                                </Col>
                              </Row>
                            </>) 
                            }
                            <h2 className="mb-3">Case Info</h2>
                            <Row>
                              <Col>
                                <Label>Title</Label>
                                <Field name="title">
                                  {({ input, meta }) => (
                                      <div>
                                        <Input
                                          {...input}
                                          type="text"
                                          className="form-control"
                                          invalid={meta.error && meta.touched}
                                        />
                                        {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                                      </div>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs="12" sm="6">
                                <Label for="categoryId">Category</Label>
                                <Field name="categoryId">
                                  {({ input, meta }) => (
                                      <div>
                                        <Input
                                          {...input}
                                          type="select"
                                          className="form-control"
                                          onChange={(e) => {handleCategoryChange(e.target.value, form.change)}}
                                          invalid={meta.error && meta.touched}
                                        >
                                        <option />
                                        {categories && categories.map((option, index) => (
                                          <option key={index} value={option.categoryId}>
                                            {option.name}
                                          </option>
                                        ))}
                                        </Input>
                                        {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                                      </div>
                                  )}
                                </Field>
                              </Col>
                              <Col xs="12" sm="6">
                              <Label for="subCategoryId">Sub-category</Label>
                                <Field name="subCategoryId">
                                  {({ input, meta }) => (
                                      <div>
                                        <Input
                                          {...input}
                                          type="select"
                                          className="form-control"
                                          disabled={subCategories.length === 0}
                                          invalid={meta.error && meta.touched}
                                        >
                                        <option />
                                        {subCategories && subCategories.map((option, index) => (
                                          <option key={index} value={option.subCategoryId}>
                                            {option.name}
                                          </option>
                                        ))}
                                        </Input>
                                        {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                                      </div>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            {customerId &&
                            (<>
                            <Row className="mt-3">
                              <Col>
                              <Field 
                              name="projectCheck" 
                              component="input" 
                              type="checkbox"
                              className="form-check-input" 
                                onClick={(e) => {
                                  setprojectCheck(e.target.checked)
                                }}/>
                              <Label className="mx-2 my-0">Related to Project?</Label>
                              </Col>
                            </Row>
                            {!!projectCheck &&
                            <Row className="mt-3">
                              <Col>
                                <Label>Projects</Label>
                                <Field
                                  name="projectId"
                                  className="form-control"
                                  component="select">
                                  <option />
                                  {projects && projects.map((option, index) => (
                                    <option key={index} value={option.projectId}>
                                      {option.name}
                                    </option>
                                  ))}
                                </Field>
                              </Col>
                            </Row>
                            }
                            
                            </>)}
                            <Row className="mt-5">
                              <Col className="d-flex justify-content-between w-100 align-items-center">
                                <Button onClick={() => {
                                  history.goBack();
                                }} className="btn btn-danger btn-lg">Cancel</Button>
                                <Button className="btn btn-takamol btn-lg" block disabled={loading || !valid} type="submit">
                                  Submit
                                </Button>
                              </Col>
                            </Row>
                          </form>
                        )}
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        );
    
}