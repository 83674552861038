import Home from './home'
import Login from './login'
import Guest from './guest'
import AddCase from './add-case'
import DisplayCase from './display-case'
import ListCases from './list-cases'
import ViewCase from './view-case'

let paths = [
    Home,
    Login,
    Guest,
    ...AddCase, 
    DisplayCase,
    ListCases,
    ViewCase,
];

export default paths.map((path) => {
    return path;
});