import { Login } from './login.component';
import { connect } from 'react-redux';
import { login } from '../../redux/auth/auth.epics';

const mapStateToProps = (state) => {
  return {
    customerId: state.auth.customerId,
    loading: state.auth.loading,
    error: state.auth.error,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleSubmit: (data) => {
      dispatch(login(data));
    },
  };
};

export const LoginContainer = connect(mapStateToProps, mapDispatchToProps)(Login);
