import { SuccessPage } from './success.component';
import { connect } from 'react-redux';


const mapStateToProps = (state) => {
  return {
    caseId: state.case.caseId
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
   
  };
};

export const SuccessContainer = connect(mapStateToProps, mapDispatchToProps)(SuccessPage);
