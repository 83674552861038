import * as types from './case.types';

export const initialState = {
    cases: null,
    singleCase: null,
    caseId: null,
    error: null,
    loading: false,
    message: null
};


export const caseReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.GET_CASES_BY_CUSTOMER_ID: 
            return {
              ...state,
              cases: null,
              loading: true,
              error: null
            };
        case types.GET_CASES_BY_ID:
            return {
                ...state,
                singleCase: null,
                loading: true,
                error: null,
                message: null
            };
        case types.ADD_CASE_LOGGEDIN:
        case types.ADD_CASE_GUEST:
        case types.UPDATE_CASE_SURVEY:
            return {
                ...state,
                loading: true,
                caseId: null,
                error: null,
                message: null
            };
        case types.ADD_CASE_LOGGEDIN_SUCCESS:
        case types.ADD_CASE_GUEST_SUCCESS:
            return {
                ...state,
                loading: false,
                caseId: action.caseId,
            };
        case types.UPDATE_CASE_SURVEY_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.message
            };
        case types.GET_CASES_BY_CUSTOMER_ID_SUCCESS:
            return {
                ...state,
                cases: action.cases,
                loading: false
            };
        case types.GET_CASES_BY_ID_SUCCESS:
            return {
                ...state,
                singleCase: action.singleCase,
                loading: false
            };
        case types.GET_CASES_BY_ID_FAILED:
        case types.GET_CASES_BY_CUSTOMER_ID_FAILED:
        case types.ADD_CASE_LOGGEDIN_FAILED:
        case types.ADD_CASE_GUEST_FAILED:
        case types.UPDATE_CASE_SURVEY_FAILED:
            return {
                ...state,
                error: action.error,
                caseId: null,
                cases: null,
                singleCase: null,
                loading: false
            };
      default:
          return state
    }
}