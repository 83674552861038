import React from "react";

function Footer() {
    return (
        <>
        <footer className="mt-auto">
            <div className="container px-5">
                <div className="row">
                    <div className="col text-center"><div className="small">&copy;2021 Takamol Holding</div></div>
                </div>
            </div>
        </footer>
        </>
    );
}

export default Footer;


