import React, { Component } from 'react';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap';
import { Link } from 'react-router-dom';
import { paths } from '../../shared/paths'
import { redirectToLogin, redirectToListCases, getCustomerId } from '../../shared/authService';
import { getCaseOrigin } from '../../shared/caseOriginService';

export class HomePage extends Component {
  componentDidMount() {
    const customerId = getCustomerId();
    const caseOrigin = getCaseOrigin();
    
    if(customerId) {
      redirectToListCases()
    } else {
      redirectToLogin(caseOrigin)
    }
  }

  render() {
    return (
      <section className="home">
      <Container>
        <Row>
          <Col className="text-center">
            <h1>Welcome to Takamol CRM</h1>
            <p className="mb-5">Login with your credentials or continue as a guest</p>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="6" md={{ size: 4, offset: 2 }}>
            <Link to={paths.login}>
              <Card className="icon-card">
                <CardBody>
                  <CardImg top src="assets/images/login.svg" alt="Icon" />
                  <CardTitle tag="h4" className="login-text">
                    Login
                  </CardTitle>
                </CardBody>
              </Card>
            </Link>
          </Col>

          <Col xs="12" sm="6" md="4">
            <Link to={paths.guest}>
              <Card className="icon-card">
                <CardBody>
                  <CardImg top src="assets/images/guest.svg" alt="Icon" />
                  <CardTitle tag="h4" className="guest-text">
                    Continue as Guest
                  </CardTitle>
                </CardBody>
              </Card>
            </Link>
          </Col>
        </Row>
      </Container>
      </section>
    );
  }
}
