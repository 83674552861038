import * as actions from './case.actions';
import { HttpClient } from '../../utils/httpClient';
import { paths } from '../../shared/paths';
import { formBody } from '../../shared/authService';
import history from '../../history';
import { getCaseOrigin } from '../../shared/caseOriginService';


export const fetchCasesByCustomerId = (customerId, caseOrigin) => {
    return function (dispatch) {
        dispatch(actions.GetCasesByCustomerId());
        HttpClient({
            url: '/api/data/GetAllCasesByCustomerID',
            method: 'GET',
            params: {'CustomerID': customerId, 'CaseOrigin': caseOrigin}
        })
        .then((res) => dispatch(actions.GetCasesByCustomerIdSuccess(res)))
        .catch((err) => dispatch(actions.GetCasesByCustomerIdFailed(err)));
    }
}

export const fetchCasesById = (data) => {
    return function (dispatch) {
        dispatch(actions.GetCasesById());
        HttpClient({
            url: '/api/data/GetCaseByID',
            method: 'GET',
            params: data
        })
        .then((res) => {
            dispatch(actions.GetCasesByIdSuccess(res))
            history.replace(`/case/${data['CaseID']}`);
        })
        .catch((err) => dispatch(actions.GetCasesByIdFailed(err)));
    }
}

export const addCaseForLoggedin = (data, customerId, caseOrigin) => {
    return function (dispatch) {        
        const caseOrigin = getCaseOrigin()

        dispatch(actions.AddCaseLoggedIn());
        HttpClient({
            url: '/api/data/CreateCaseForLoggedInUser',
            method: 'POST',
            data: formBody({...data, 'customerid': customerId, 'caseOrigin': caseOrigin})
        })
        .then((res) => {
            dispatch(actions.AddCaseLoggedInSuccess(res));
            history.push(paths.addCaseSuccess);
        })
        .catch((err) => dispatch(actions.AddCaseLoggedInFailed(err)));
    }
}

export const addCaseForGuest = (data) => {
    return function (dispatch) {
        dispatch(actions.AddCaseGuest());
        HttpClient({
            url: 'api/data/CreateCaseForAnonymousUser',
            method: 'POST',
            data: formBody(data)
        })
        .then((res) => {
            dispatch(actions.AddCaseGuestSuccess(res));
            history.push(paths.addCaseSuccess);
        })
        .catch((err) => dispatch(actions.AddCaseGuestFailed(err)));
    }
}

export const updateCaseSurvey = (data, caseId) => {
    data = data.survey

    return function (dispatch) {
        dispatch(actions.UpdateCaseSurvey());
        HttpClient({
            url: '/api/data/UpdateCaseSurvey',
            method: 'POST',
            data: formBody(data)
        })
        .then((res) => dispatch(actions.UpdateCaseSurveySuccess(res)))
        .catch((err) => dispatch(actions.UpdateCaseSurveyFailed(err)));
    }
}

