import React from 'react';
import { connect } from 'react-redux';

const Route = ({ component: Component, ...props }) => {
    return <Component {...props} />
};

const mapStateToProps = (state) => {
  return {
    user: null,
  };
};

export default connect(mapStateToProps)(Route);