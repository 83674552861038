// 1.1.2	GET PREDEFINED CATEGORY
// 1.1.3	GET SUBCATEGORY BY CATEGORY
// 1.1.4	GET PREDEFINED PROJECT BY CUSTOMER ID

export const GET_CATEGORIES = 'GET_CATEGORIES';
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS';
export const GET_CATEGORIES_FAILED = 'GET_CATEGORIES_FAILED';

export const GET_SUBCATEGORIES = 'GET_SUBCATEGORIES';
export const GET_SUBCATEGORIES_SUCCESS = 'GET_SUBCATEGORIES_SUCCESS';
export const GET_SUBCATEGORIES_FAILED = 'GET_SUBCATEGORIES_FAILED';

export const GET_PROJECT_BY_CUSTOMER_ID = 'GET_PROJECT_BY_CUSTOMER_ID';
export const GET_PROJECT_BY_CUSTOMER_ID_SUCCESS = 'GET_PROJECT_BY_CUSTOMER_ID_SUCCESS';
export const GET_PROJECT_BY_CUSTOMER_ID_FAILED = 'GET_PROJECT_BY_CUSTOMER_ID_FAILED';