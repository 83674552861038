import { ListCases } from './list-cases.component';
import { connect } from 'react-redux';
import { fetchCasesByCustomerId } from '../../redux/case/case.epics';

const mapStateToProps = (state) => {
  return {
    // customerId: state.auth.customerId,
    loggedin: state.auth.loggedin,
    cases: state.case.cases,
    loading: state.case.loading,
    error: state.case.error
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCasesByCustomerId: (customerId, caseOrigin) => {
      dispatch(fetchCasesByCustomerId(customerId, caseOrigin));
    }
  };
};

export const ListCasesContainer = connect(mapStateToProps, mapDispatchToProps)(ListCases);
