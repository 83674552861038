import axios from 'axios';
import { getToken, handleUnauthorized } from '../shared/authService';


export function HttpClient(options) {
  return axios({
    baseURL: 'https://crm.takamol.com.sa/',
    headers: getHeaders(options),
    ...options,
  })
    .then(onSuccess)
    .catch(onError);
}

function onSuccess(res) {
  if (res.data) return Promise.resolve(res.data);
  return Promise.resolve(res.data);
}

function onError(err) {
  if (err.response && err.response.status === 401) {
    handleUnauthorized();
  }
  const msg = err.response.data['Message'];
  return Promise.reject(msg);
}

function getHeaders(options) {
  const token = getToken();
  const customHeaders = { ...options.headers };
  customHeaders['Content-type'] = 'application/x-www-form-urlencoded';

  if (token) customHeaders.Authorization = `Bearer ${token}`;
  return customHeaders;
}
