import { HomePage } from './home.component';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return {
    customerId: state.auth.customerId,
    loggedin: state.auth.loggedin
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export const HomePageContainer = connect(mapStateToProps, mapDispatchToProps)(HomePage);
