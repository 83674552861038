import * as actions from './auth.actions';
import { HttpClient } from '../../utils/httpClient';
import { setTokens, formBody, setCustomerId, removeCustomerId } from '../../shared/authService';
import { USERNAME, PASSWORD, GRANT_TYPE } from '../../shared/config';
import history from '../../history';
import { paths } from '../../shared/paths';

export const login = (data) => {
    return function (dispatch) {
        dispatch(actions.login());
        HttpClient({
            url: 'api/data/AuthenticateUser',
            method: 'POST',
            data: formBody(data)
        })
        .then((res) => {
            setCustomerId(res);
            dispatch(actions.loginSuccess(res))
            history.replace(paths.listCases);
        })
        .catch((err) => dispatch(actions.loginFailed(err)));
    }
}

export const loadAuth = () => {
    return function (dispatch) {
        var data = {'username': USERNAME, 'password': PASSWORD, 'grant_type':  GRANT_TYPE}
        
        dispatch(actions.auth());
        HttpClient({
            url: 'token',
            method: 'POST',
            data: formBody(data)
        })
        .then((res) => {
            setTokens(res.access_token);
            dispatch(actions.authSuccess(res))
        })
        .catch((err) => dispatch(actions.authFailed(err)));
    }
}


export const logout = () => {
    return function (dispatch) {
        removeCustomerId();
        dispatch(actions.logout());
        history.replace(paths.home);
    }
}