import { AddCase } from './add-case.component';
import { connect } from 'react-redux';
import { fetchCategories, fetchSubCategories, fetchProjectsbyCustomerId } from '../../redux/category/category.epics';
import { addCaseForGuest, addCaseForLoggedin } from '../../redux/case/case.epics';

const mapStateToProps = (state) => {
  return {
    // customerId: state.auth.customerId,
    categories: state.categories.categories,
    projects: state.categories.project,
    subCategories: state.categories.subCategories,
    error: state.case.error,
    loading: state.case.loading
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCategories: () => {
      dispatch(fetchCategories());
    },
    fetchSubcategories: (categoryId) => {
      dispatch(fetchSubCategories(categoryId));
    },
    fetchProjectsbyCustomerId: (customerId, projectOrigin) => {
      dispatch(fetchProjectsbyCustomerId(customerId, projectOrigin));
    },
    handleSubmit: (data, customerId) => {
      if (customerId) {
        dispatch(addCaseForLoggedin(data, customerId));
      } else {
        dispatch(addCaseForGuest(data));
      }
    },
  };
};

export const AddCaseContainer = connect(mapStateToProps, mapDispatchToProps)(AddCase);
