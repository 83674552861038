import { combineReducers } from 'redux';
import { categoryReducer } from './category/category.reducer';
import { authReducer } from './auth/auth.reducer';
import { caseReducer } from './case/case.reducer';

const rootReducer = combineReducers({
    categories: categoryReducer,
    auth: authReducer,
    case: caseReducer
});

export default rootReducer;