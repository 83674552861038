import React from 'react';
import { Container, Row, Col } from 'reactstrap';

export function SuccessPage(props)  {
    return (
      <section className="p-5">
        <Container>
            <Row>
              <Col className="text-center mb-5">
                  <img className="success-icon mb-3" src="../assets/images/success.svg" alt="icon" />
                  <h1>Success!</h1>
                  <p>Case Submitted successfully</p>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                  Case  ID: {props.caseId}
              </Col>
            </Row>
        </Container>
      </section>
    );
}
