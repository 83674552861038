import { AddCaseContainer } from './add-case.container';
import { SuccessContainer } from './success.container';
import { paths } from '../../shared/paths';

export default [
  {
    path: paths.addCase,
    exact: true,
    title: 'title.addCase',
    component: AddCaseContainer,
  }, 
  {
    path: paths.addCaseSuccess,
    exact: true,
    title: 'title.addCaseSuccess',
    component: SuccessContainer,
  }, 
];
