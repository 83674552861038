import React, { useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Label, Spinner } from 'reactstrap';
import { caseStatus } from '../../shared/constants';
import { Link, Redirect } from 'react-router-dom';
import { paths } from '../../shared/paths'
import { getCaseOrigin } from '../../shared/caseOriginService';
import { getCustomerId } from '../../shared/authService';

export function ListCases(props) {
  const {
   cases,
   fetchCasesByCustomerId,
   loading
  } = props;

  const customerId = getCustomerId();
  const caseOrigin = getCaseOrigin();

   useEffect(() => {
      if (customerId) {
         fetchCasesByCustomerId(customerId, caseOrigin)
         // fetchCasesByCustomerId('90dfb507-87c7-eb11-bacc-000d3ab7dfec', caseOrigin)
      }
   }, [fetchCasesByCustomerId, customerId, caseOrigin]);
 
   return (
    <section className="list-case px-3 px-sm-5 py-5">
      {!customerId ? (
      <Redirect to={paths.login}/>
      ) : (
         <Container>
         <Row>
            <Col className="text-center mb-5">
               <div className="d-flex justify-content-between align-items-center">
                  <h1>User cases </h1>
                  <Link className="btn btn-lg btn-takamol text-white"  to={paths.addCase}>Add Case</Link>
               </div>
            </Col>
         </Row>
         <Row className="d-flex justify-content-center align-items-top">
        {cases ? cases.map((caseDetailes, index) => (
            <Col xs="12" md="6" xl="4" className="mb-5" key={index}>
               <Card>
                  <CardBody>
                     <CardTitle tag="h2" className="mb-4">{caseDetailes.ticketnumber}</CardTitle>
                     <Row>
                           <Col xs="12" sm="6">
                              <Label>Title</Label>
                              <p>{caseDetailes.title}</p>
                           </Col>
                           <Col xs="12" sm="6">
                              <Label>Priority Code</Label>
                              <p>{caseDetailes.prioritycode}</p>
                           </Col>
                           <Col xs="12" sm="6">
                              <Label>Category</Label>
                              <p>{caseDetailes.category?.name}</p>
                           </Col>
                           <Col xs="12" sm="6">
                              <Label>Sub-category</Label>
                              <p>{caseDetailes.subCategory?.name}</p>
                           </Col>
                           {caseDetailes.project.name &&
                           <Col xs="12" sm="6">
                              <Label>Project</Label>
                              <p>{caseDetailes.project.name }</p> 
                           </Col>
                           } 
                     </Row>
                     <div className={`case-status case-status--${caseStatus[caseDetailes.statuscode].toLowerCase().replace(/ /g,"-")}`}>
                        {caseStatus[caseDetailes.statuscode]}
                     </div>
                     { caseDetailes.statuscode === 5 &&
                        <Row className="mt-5">
                           {caseDetailes.survey && 
                              <Link className="btn btn-lg btn-takamol text-white" to={`/case/${caseDetailes.ticketnumber}`}>
                              {/* <Link className="btn btn-lg btn-takamol text-white" to={`/case/017e7219-b698-4661-b347-faf7e8923213`}> */}
                                 Survey 
                              </Link>
                           }
                        </Row>
                     }
                  </CardBody>
               </Card>
            </Col>
        )) : 
        <Col className="mb-5 w-auto">
           {loading ? <Spinner />  : 'No Cases Exist'}
        </Col>
        }
        </Row>
      </Container>
      )}
    </section>
   )
}