export const caseOriginArray = [
    {name: 'CRM', value: '1'},
    {name: 'Takamol Portal', value: '2'},
    {name: 'Takamol TBS', value: '3'},
    {name: 'Future Work', value: '4'},
    {name: 'حلول', value: '5'},
    {name: 'Email', value: '6'},
    {name: 'Phone', value: '7'},
]

export const caseStatus = {
    1: 'New',
    2: 'Assign to External Party', 
    3: 'In Progress', 
    4: 'Researching', 
    5: 'Resolved', 
    1000: 'Information Provided',
    6: 'Cancelled',
    2000: 'Merged',
}

export const surveyRating = [
    {key: 1, value: '1'},
    {key: 2, value: '2'},
    {key: 3, value: '3'},
    {key: 4, value: '4'},
    {key: 5, value: '5'},
]