import React, { Component } from 'react';
import { Container, Row, Col, Card, CardImg, CardBody, CardTitle } from 'reactstrap'
import { Link, Redirect } from 'react-router-dom';
import { paths } from '../../shared/paths'
import { getCustomerId } from '../../shared/authService';
export class Guest extends Component {
  render() {
  const customerId = getCustomerId();

    return (
      <section className="guest">
        {!customerId ? (
        <Container>
            <Row>
              <Col className="text-center">
                <h1>Welcome Guest</h1>
                <p className="mb-5">Select an Option below</p>
              </Col>
            </Row>
                <Row>
                  <Col xs="12" sm="6" md={{ size: 4, offset: 2 }}>
                    <Link to={paths.addCase}>
                      <Card className="icon-card">
                        <CardBody>
                          <CardImg top src="assets/images/case.svg" alt="Icon" />
                          <CardTitle tag="h4">
                          Create New Case
                          </CardTitle>
                        </CardBody>
                      </Card>
                    </Link>
                  </Col>

                  <Col xs="12" sm="6" md="4">
                  <Link to={paths.displayCase}>
                      <Card className="icon-card">
                        <CardBody>
                          <CardImg top src="assets/images/view-case.svg" alt="Icon" />
                          <CardTitle tag="h4">
                          Display Case
                          </CardTitle>
                        </CardBody>
                      </Card>
                  </Link>
                  </Col>
                </Row>
        </Container>
      ): (
        <Redirect to={paths.listCases} />
      )}
     </section>
    );
  }
}