import * as types from './auth.types';

export const login = () => ({
    type: types.LOGIN,
});
  
export const loginSuccess = (customerId) => ({
    type: types.LOGIN_SUCCESS,
    customerId: customerId,
});
  
export const loginFailed = (error) => ({
    type: types.LOGIN_FAILED,
    error,
});

export const auth = () => ({
    type: types.AUTH
});
export const authSuccess = (token) => ({
    type: types.AUTH_SUCCESS,
    token: token.access_token
});
export const authFailed = (error) => ({
    type: types.AUTH_FAILED,
    error
});

export const logout = () => ({
    type: types.LOGOUT
})