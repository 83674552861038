import { connect } from 'react-redux';
import { App } from './app.component';
import { loadAuth } from './redux/auth/auth.epics';

const mapStateToProps = (state) => {
  return {
    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadAuth: () => {
      dispatch(loadAuth());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
