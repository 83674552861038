import React from 'react';
import { Form, Field } from 'react-final-form';
import { Container, Row, Col, Button, Label, Card, CardBody, FormFeedback, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { Error } from '../../components/error.component';

export function DisplayCase(props){
    const { handleSubmit, error } = props;
    const history = useHistory();

        return (
            <section className="display-case px-3 px-sm-5 py-5">
            <Container>
              <Row>
                <Col className="text-center mb-5">
                  <h1>Display Case</h1>
                </Col>
              </Row>
              <Row>
                <Col xs="12" md={{ size: 6, offset: 3 }} xl={{ size: 4, offset: 4 }}>
                  { error && (
                    <Row>
                      <Col>
                        <Error error={error} />
                      </Col>
                    </Row>
                  )
                  }
                  <Card className="form-card">
                    <CardBody>
                  <Form 
                    onSubmit={handleSubmit}
                    validate={(values) => {
                      const errors = {};
                      if (!values.CaseID) {
                        errors.CaseID = "Required";
                      }
                      return errors;
                    }} 
                  >
                    {({ handleSubmit, valid }) => (
                      <form onSubmit={handleSubmit}>
                        <Row>
                            <Col className="d-flex flex-column">
                                <Label>Case ID</Label>
                                <Field name="CaseID">
                              {({ input, meta }) => (
                                <div>
                                  <Input
                                    {...input}
                                    type="text"
                                    className="form-control"
                                    invalid={meta.error && meta.touched}
                                  />
                                  {meta.error && meta.touched && <FormFeedback>{meta.error}</FormFeedback>}
                                </div>
                              )}
                              </Field>
                            </Col>
                        </Row>

                        <Row className="mt-3">
                          <Col className="d-flex justify-content-between w-100 align-items-center">
                                <Button onClick={() => {
                                  history.goBack();
                                }} className="btn btn-danger btn-lg">Cancel</Button>
                                <Button className="btn btn-takamol btn-lg" block type="submit" disabled={!valid}>
                              View
                            </Button>
                          </Col>
                        </Row>
                      </form>
                    )}
                  </Form>
                  </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        );
}