import { Switch } from 'react-router-dom';
import routes from './routes';
import Route from './shared/route.component';
import Footer from './components/footer.component';
import Header from './components/header.component';
import Error from './routes/error/error.component';
import { useEffect } from 'react';
import { isAuthenticated } from './shared/authService';
import CacheBuster from './cacheBuster';
import './App.css';

export function App(props) {
  const { loadAuth } = props;

  useEffect(() => {
    if (!isAuthenticated()) {
      loadAuth();
    }
  }, [loadAuth]);
return (
  <CacheBuster>
  {({ loading, isLatestVersion, refreshCacheAndReload }) => {
    if (loading) return null;
    if (!loading && !isLatestVersion) {
      refreshCacheAndReload();
    }
  return (
    <div className="App">
      <Header/>
      <Switch>
        {routes && routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            component={route.component}
            {...route.additionalProps}
          />
        ))}
        <Route component={Error}/>
      </Switch> 
      <Footer/>
    </div>
  );
  }}
</CacheBuster>
);
}
