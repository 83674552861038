import * as types from './auth.types';

export const initialState = {
    customerId: null,
    token: null,
    error: null,
    loading: false,
    loggedin: false
};


export const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.LOGIN: 
        return {
          ...state,
          loading: true,
          error: null,
        };
      case types.AUTH: 
        return {
          ...state,
          token: null,
          loading: true,
        };
      case types.LOGOUT: 
        return {
          ...state,
          customerId: null,
          loggedin: false,
        };
      case types.AUTH_SUCCESS:
        return {
          ...state,
          token: action.token,
          loading: false
        };
      case types.LOGIN_SUCCESS:
        return {
            ...state,
            customerId: action.customerId,
            loading: false,
            loggedin: true
        };
      case types.LOGIN_FAILED:
      case types.AUTH_FAILED:
        return {
            ...state,
            loggedin: false,
            error: action.error,
            loading: false
        };
      default:
          return state
    }
}