import * as types from './category.types';

export const initialState = {
    categories: [],
    subCategories: [],
    projects: [],
    error: null,
    loading: false,
};


export const categoryReducer = (state = initialState, action) => {
    switch (action.type) {
      case types.GET_CATEGORIES: 
        return {
          ...state,
          categories: [],
          loading: true,
          error: null
        };
      case types.GET_SUBCATEGORIES:
        return {
            ...state,
            subCategories: [],
            loading: true,
            error: null
        };
      case types.GET_PROJECT_BY_CUSTOMER_ID:
        return {
            ...state,
            projects: [],
            loading: true,
            error: null
        };
      case types.GET_PROJECT_BY_CUSTOMER_ID_SUCCESS:
        return {
            ...state,
            projects: action.projects,
            loading: false
        };
      case types.GET_SUBCATEGORIES_SUCCESS:
        return {
            ...state,
            subCategories: action.subCategories,
            loading: false
        };
      case types.GET_CATEGORIES_SUCCESS:
        return {
            ...state,
            categories: action.categories,
            loading: false
        };
      case types.GET_CATEGORIES_FAILED: 
      case types.GET_SUBCATEGORIES_FAILED:
      case types.GET_PROJECT_BY_CUSTOMER_ID_FAILED:
        return {
            ...state,
            error: action.error,
            loading: false
        };
      default:
          return state
    }
}