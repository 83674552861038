import * as types from './case.types';

export const GetCasesByCustomerId = () => ({
    type: types.GET_CASES_BY_CUSTOMER_ID
});
export const GetCasesByCustomerIdSuccess = (data) => ({
    type: types.GET_CASES_BY_CUSTOMER_ID_SUCCESS,
    cases: data
});
export const GetCasesByCustomerIdFailed = (error) => ({
    type: types.GET_CASES_BY_CUSTOMER_ID_FAILED,
    error
});

export const GetCasesById = () => ({
    type: types.GET_CASES_BY_ID
});
export const GetCasesByIdSuccess = (data) => ({
    type: types.GET_CASES_BY_ID_SUCCESS,
    singleCase: data
});
export const GetCasesByIdFailed = (error) => ({
    type: types.GET_CASES_BY_ID_FAILED,
    error
});

export const AddCaseLoggedIn = () => ({
    type: types.ADD_CASE_LOGGEDIN
});
export const AddCaseLoggedInSuccess = (data) => ({
    type: types.ADD_CASE_LOGGEDIN_SUCCESS,
    caseId: data
});
export const AddCaseLoggedInFailed = (error) => ({
    type: types.ADD_CASE_LOGGEDIN_FAILED,
    error
});

export const AddCaseGuest = () => ({
    type: types.ADD_CASE_GUEST
});
export const AddCaseGuestSuccess = (data) => ({
    type: types.ADD_CASE_GUEST_SUCCESS,
    caseId: data
});
export const AddCaseGuestFailed = (error) => ({
    type: types.ADD_CASE_GUEST_FAILED,
    error
});

export const UpdateCaseSurvey = () => ({
    type: types.UPDATE_CASE_SURVEY
});
export const UpdateCaseSurveySuccess = (data) => ({
    type: types.UPDATE_CASE_SURVEY_SUCCESS,
    message: data
});
export const UpdateCaseSurveyFailed = (error) => ({
    type: types.UPDATE_CASE_SURVEY_FAILED,
    error
});