import * as types from './category.types';

export const getCategories = () => ({
    type: types.GET_CATEGORIES,
});

export const getCategoriesSuccess = (data) => ({
    type: types.GET_CATEGORIES_SUCCESS,
    categories: data
});

export const getCategoriesFailed = (error) => ({
    type: types.GET_CATEGORIES_FAILED,
    error
})
export const getSubCategories = () => ({
    type: types.GET_SUBCATEGORIES,
});

export const getSubCategoriesSuccess = (data) => ({
    type: types.GET_SUBCATEGORIES_SUCCESS,
    subCategories: data
});

export const getSubCategoriesFailed = (error) => ({
    type: types.GET_SUBCATEGORIES_FAILED,
    error
});

export const getProjectByCustomerId = () => ({
    type: types.GET_PROJECT_BY_CUSTOMER_ID
});

export const getProjectByCustomerIdSuccess = (data) => ({
    type: types.GET_PROJECT_BY_CUSTOMER_ID_SUCCESS,
    projects: data
});

export const getProjectByCustomerIdFailed = (error) => ({
    type: types.GET_PROJECT_BY_CUSTOMER_ID_FAILED,
    error
});