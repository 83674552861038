import * as actions from './category.actions';
import { HttpClient } from '../../utils/httpClient';

export const fetchCategories = () => {
    return function (dispatch) {
        dispatch(actions.getCategories());
        HttpClient({
            url: 'api/data/GetPredefinedCategory',
            method: 'GET'
        })
        .then((res) => dispatch(actions.getCategoriesSuccess(res)))
        .catch((err) => dispatch(actions.getCategoriesFailed(err)));
    }
}

export const fetchSubCategories = (categoryId) => {
    return function (dispatch) {
        dispatch(actions.getSubCategories());
        HttpClient({
            url: 'api/data/GetSubCategoryByCategoryID',
            method: 'GET',
            params: {'CategoryID': categoryId}
        })
        .then((res) => dispatch(actions.getSubCategoriesSuccess(res)))
        .catch((err) => dispatch(actions.getSubCategoriesFailed(err)));
    }
}

export const fetchProjectsbyCustomerId = (customerId, projectOrigin) => {
    return function (dispatch) {
        dispatch(actions.getProjectByCustomerId());
        HttpClient({
            url: '/api/data/GetPredefinedProjectByCustomerID',
            method: 'GET',
            params: {'CustomerID': customerId, 'projectorigin': projectOrigin}
        })
        .then((res) => dispatch(actions.getProjectByCustomerIdSuccess(res)))
        .catch((err) => dispatch(actions.getProjectByCustomerIdFailed(err)));
    }
}