import { Case } from './view-case.component';
import { connect } from 'react-redux';
import { updateCaseSurvey } from '../../redux/case/case.epics';
import { fetchCasesById } from '../../redux/case/case.epics';

const mapStateToProps = (state) => {
  return {
    singleCase: state.case.singleCase,
    message: state.case.message
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchCasesById: (id) => {
      var data = {'CaseID': id}
      dispatch(fetchCasesById(data));
    },
    handleSubmit: (data, caseId) => {
      dispatch(updateCaseSurvey(data, caseId));
    }
  };
};

export const CaseContainer = connect(mapStateToProps, mapDispatchToProps)(Case);
